import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import {SplitStyling} from '../styles/pages/default-split.styled'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import Arrow from '../images/arrow_down_right.inline.svg'         
import gsap from 'gsap'
import Seo from '../components/global/seo'

export default function Demos({data}) {
    const theImage = getImage(data?.wpPage?.demoPageFields?.demosImage?.localFile);

    const transformPanels = () => {
      gsap.to('.translator-1', {x:'-100%', opacity:0})
      gsap.to('.translator-2', {x:'0%', opacity:1})
    }
            
    return (
        <SplitStyling>
           <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
            <div className="left image-wrapper">
                <GatsbyImage image={theImage} className="image" alt={'test'} />
            </div>
            <div className="right">
              <div className="translator active translator-1">
                <div className="right-inner">
                  <h2 className="main_title"><Arrow />  Demos</h2>
                  <div className="text">{parse(data.wpPage.demoPageFields.demoIntroText)}</div>
                  <button className="capsule_button black" onClick={transformPanels}>Start submission</button>
                </div>
              </div>
              <div className="translator translator-2">
                 {parse(data.wpPage.demoPageFields.virppEmbedCode)}
              </div>
            </div>
        </SplitStyling>
    )
}

export const query = graphql`
  {
    wpPage(title: {eq: "Demos"}) {
      id
      seo {
        metaDesc
        title
      }
      demoPageFields {
        virppEmbedCode
        demoIntroText
        demosImage {
            localFile{
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
        }
      }
    }
  }
`